import { useContext } from 'react';
import Header from '../../components/header/Header';
import WorkDetails from '../Portfolio/Work/WorkDetails';
import './Videos.scss';
import { GlobalDataContext } from '../../utils/GlobalValues';
import Contact from '../../components/contact/Contact';

const Videos = () => {
    var arr = [{ text: "sadasd" }, { text: "sadasd" }, { text: "sadassadddd dddsaddddddddddddd ddddddddddd ddddddddddd dddddddddd ddddddd" }];
    const globalData = useContext(GlobalDataContext);

    return <div>
        <div className="welcome | flex-column center-x gap-s | text-white textshadow bg-img8">
            <Header />
            <WorkDetails workId="ninguno" substitute={{ title: globalData.value.videosFeaturedTitle, description: "Ejemplo de descripción-", substitute: <iframe className="shadow roundborders" src="https://www.youtube.com/embed/GFBs4Dx6Hs0?si=u4QAUSScmLjZmDAH" frameBorder={0} title="YouTube video player" allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe> }
            }></WorkDetails>
        </div>
        <div className="videos-wrapper pad-l bg-img9 flex-column center">
            <h2 className='bold pad-xxxs' >TODOS LOS VÍDEOS</h2>
            <div className="videos vpad-m gap-m">
                {arr.map(e => {
                    return <div className="videos__card | bg-white shadow">
                        <img src="https://www.wappingweb.com/wp-content/uploads/cultura-data-driven-uai-720x405.jpg" />
                        <div className="videos__card__text | flex-column pad-xs gap-xxs">
                            <h4>Título</h4>
                            <p>{e.text}</p>
                        </div>
                    </div>
                })}
            </div>
        </div>
        <div className='videos__contact'>
            <Contact />
        </div>
    </div>
}

export default Videos;