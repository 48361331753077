import { Image } from "../types/Types";
import { createContext, useEffect, useState } from 'react';

export interface GlobalData {
    homeWelcomeTitle: string;
    homeWelcomeDescription: string;
    homeBgVideoUrl: string;

    homePortfolioTitle: string;
    homePortfolioDescription: string;
    homePortfolioImages: Image[];
    homePortfolioButton: string;

    portfolioTitle: string;
    portfolioSeemore: string;
    portfolioGoback: string;
    portfolioFeaturedVideoUrl: string;

    aboutmeTitle: string;
    aboutmeDescription: string;
    aboutmeExperience: string;
    aboutMeShowSkillNumber: boolean;
    aboutMePictureUrl: string;

    videosFeaturedTitle: string;

    contactSlogan: string;
    contactName: string;
    contactEmail: string;
    contactMessage: string;
    contactSend: string;
    contactSocialMedia: string;
    contactWebform: string;
    contactEmailAddress: string;

    navbarTitle: string;
    navbarHome: string;
    navbarPortfolio: string;
    navbarVideos: string;
    navbarAboutme: string;

    showVideoPage: boolean;

    emailjsServiceId: string;
    emailjsTemplateId: string;
    emailjsUserKey: string;
    emailjsSuccessMessage: string;
}

const defaultGlobalData: GlobalData = {
    homeWelcomeTitle: "",
    homeWelcomeDescription: "",
    homeBgVideoUrl: "",

    homePortfolioTitle: "",
    homePortfolioDescription: "",
    homePortfolioImages: [{ url: "", width: 0, height: 0 }],
    homePortfolioButton: "",

    portfolioTitle: "",
    portfolioSeemore: "",
    portfolioGoback: "",
    portfolioFeaturedVideoUrl: "",

    aboutmeTitle: "",
    aboutmeDescription: "",
    aboutmeExperience: "",
    aboutMeShowSkillNumber: false,
    aboutMePictureUrl: "",

    videosFeaturedTitle: "",

    contactSlogan: "",
    contactName: "",
    contactEmail: "",
    contactMessage: "",
    contactSend: "",
    contactSocialMedia: "",
    contactWebform: "",
    contactEmailAddress: "",

    navbarTitle: "",
    navbarHome: "",
    navbarPortfolio: "",
    navbarVideos: "",
    navbarAboutme: "",

    showVideoPage: false,

    emailjsServiceId: "",
    emailjsTemplateId: "",
    emailjsUserKey: "",
    emailjsSuccessMessage: ""
};


export interface State<T> {
    value: T;
    set: (value: T) => void;
}


export const GlobalDataContext = createContext<State<GlobalData>>({ value: defaultGlobalData, set: () => { } });

export const useGlobalData = (): State<GlobalData> => {
    const [data, setData] = useState<GlobalData>(defaultGlobalData);

    useEffect(() => {
    }, [data]);
    return {
        value: data,
        set: setData
    };
};
