import { Work, WorkExperience, Education, Skill } from "../types/Types";
import { getUserLanguage } from "../utils/Functions";
import { GlobalData } from "../utils/GlobalValues";

function createQuery(whereClause: string | undefined): string {
    return `{
         trabajoPortfolioCollection (locale:"${getUserLanguage()}", ${whereClause}){
    items{thumbnail{url, height, width}, order, type{name}, hasOwnPage, pageDescription, imagesCollection{items{url, height, width}}, title, sys{id}}
  }
}`};

function getWorks(whereClause: string | undefined, setter: ((works: any) => void), unique?: boolean): void {
    const query = createQuery(whereClause);
    window
        .fetch(`https://graphql.contentful.com/content/v1/spaces/hundvbs26llb/environments/master`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer HFJDmggU5lWtyvwL5erNeC3Jo0aP6wJcZnRowWXIJgI",
            },
            body: JSON.stringify({ query }),
        })
        .then((response) => response.json())
        .then(({ data, errors }) => {
            if (errors) {
                console.error(errors);
            }
            const works: Work[] = data.trabajoPortfolioCollection.items.map((i: any) => ({ ...i, id: i.sys.id, category: i.type.name, description: i.pageDescription, images: i.imagesCollection.items }) as Work);
            setter(unique ? works[0] : works);
        });
}

export function getAllWorkExperience(setData: (data: WorkExperience[]) => void): any {
    const query = 
    `{experienciaDeTrabajoCollection {
    items {
		role, 
		description,
      date,
      place
    }
  }}`;

    window
        .fetch(`https://graphql.contentful.com/content/v1/spaces/hundvbs26llb/environments/master`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer HFJDmggU5lWtyvwL5erNeC3Jo0aP6wJcZnRowWXIJgI",
            },
            body: JSON.stringify({ query }),
        })
        .then((response) => response.json())
        .then(({ data, errors }) => {
            if (errors) {
                console.error(errors);
            }
            setData(data.experienciaDeTrabajoCollection.items);
        });
}

export function getAllEducation(setData: (data: Education[]) => void): any {
    const query = 
    `{formacionCollection {
    items {
		education, 
      date,
      place
    }
  }}`;

    window
        .fetch(`https://graphql.contentful.com/content/v1/spaces/hundvbs26llb/environments/master`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer HFJDmggU5lWtyvwL5erNeC3Jo0aP6wJcZnRowWXIJgI",
            },
            body: JSON.stringify({ query }),
        })
        .then((response) => response.json())
        .then(({ data, errors }) => {
            if (errors) {
                console.error(errors);
            }
            setData(data.formacionCollection.items);
        });
}

export function getAllSkills(setData: (data: Skill[]) => void): any {
    const query = 
    `{habilidadCollection {
    items {
		skill, 
      level
    }
  }}`;

    window
        .fetch(`https://graphql.contentful.com/content/v1/spaces/hundvbs26llb/environments/master`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer HFJDmggU5lWtyvwL5erNeC3Jo0aP6wJcZnRowWXIJgI",
            },
            body: JSON.stringify({ query }),
        })
        .then((response) => response.json())
        .then(({ data, errors }) => {
            if (errors) {
                console.error(errors);
            }
            setData(data.habilidadCollection.items);
        });
}


export function getAllWorks(setWorks: (works: Work[]) => void): void {
    getWorks("", setWorks);
}

export function getWorkFromId(id: string, setWork: (work: Work) => void) {
    const whereClause = ` where: {sys: {id:"${id}"}}`;
    getWorks(whereClause, setWork, true);
}

export function getWorksFromType(type: string, setWorks: (works: Work[]) => void): void {
    const whereClause = `where: {type: {name: "${type}"}}`;
    getWorks(whereClause, setWorks);
}

export function getGeneralData(setData: (data: GlobalData) => void): any {
    const query = `{ contenidoGeneral(locale:"${getUserLanguage()}", id: "6RrrSSGwt7SEbdCEPKT31") {
    homeWelcomeTitle
    homeWelcomeDescription
    homePortfolioTitle
    homePortfolioDescription
    homePortfolioImagesCollection {
      items {
        url
      }
    }
    homePortfolioButton
    portfolioTitle
    portfolioSeemore
    portfolioGoback
    aboutmeTitle
    aboutmeDescription
    aboutmeExperience
    videosFeatured
    contactSlogan
    contactName
    contactEmail
    contactMessage
    contactSend
    contactSocialMedia
    contactWebform
    contactEmailAddress
    navbarTitle
    navbarHome
    navbarPortfolio
    navbarVideos
    navbarAboutme
    aboutMeShowSkillNumber
    homeBackgroundVideo {url}
    portfolioVideo
    aboutMePicture {url}
    showVideoPage
    emailjsServiceId
    emailjsTemplateId
    emailjsUserKey
    emailjsSuccessMessage
  }}`;
    window
        .fetch(`https://graphql.contentful.com/content/v1/spaces/hundvbs26llb/environments/master`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer HFJDmggU5lWtyvwL5erNeC3Jo0aP6wJcZnRowWXIJgI",
            },
            body: JSON.stringify({ query }),
        })
        .then((response) => response.json())
        .then(({ data, errors }) => {
            if (errors) {
                console.error(errors);
            }
            const generalData = data.contenidoGeneral;
            setData({
                homePortfolioImages: generalData.homePortfolioImagesCollection.items,
                homeBgVideoUrl: generalData.homeBackgroundVideo?.url,
                portfolioFeaturedVideoUrl: generalData.portfolioVideo,
                aboutMePictureUrl: generalData.aboutMePicture?.url,
                ...generalData
            })

        });
}

